<div class="text-start rounded-0 py-2 border-top">
  <div class="row">
    
    <div class="col-3 fw-bold fs-14 " id="apartment_id">
      <a [routerLink]="['/listing', apartment._id]"
        class="text-decoration-none text-dark fw-bolder">
        {{ apartment._id}}
      </a>
    </div>
    <div class="col-2 fs-14 " id="apartment_name">
      <a [routerLink]="['/listing', apartment._id]"
      class="text-decoration-none text-body hover-underline">
        {{ apartment.name }}
      </a>
    </div>

    <div class="col-2 fs-14 " id="apartment_type">
      <span class="badge text-bg-secondary rounded-pill" *ngIf="apartment.wholeApartment">
          Entire Place
      </span>
      <span class="badge text-bg-secondary rounded-pill" *ngIf="!apartment.wholeApartment">
        <ng-container *ngIf="apartment.rooms.length > 1; else singleBedroomBlock">
          Private Bedrooms
        </ng-container>
        <ng-template #singleBedroomBlock>
          Private Bedroom
        </ng-template>
      </span>
    </div>
    <div class="col-1 fs-14" id="number_of_room">
        {{ apartment.rooms.length }}
    </div>
    <div class="col-2 fs-14" id="host_info">
      <ng-template #popContent>
        <div class="row">
          <div class="col-auto">
            <span class="text-secondary">Phone</span>
          </div>
          <div class="col">
            <span>{{host.phone}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <span class="text-secondary">Email</span>
          </div>
          <div class="col">
            <a 
              class="text-decoration-none text-decoration-underline"
              href="mailto: {{host.email}}">
              {{host.email}}
            </a>
          </div>
        </div>
        
        
        
      </ng-template>
      <ng-template #popTitle>
        <span class="fw-bold">
          Contact Info
        </span>
      </ng-template>
      <a href="#" class="text-decoration-none text-decoration-underline "
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle"
      triggers="mouseenter:mouseleave"
      popoverTitle="Pop title"
      placement="start"
      [autoClose]="true">
        {{ apartment.owner.name }}
      </a>
    </div> 
    <div class="col-2 fs-14 overflow-hidden" id="apartment_status">
      <ng-container *ngIf="apartment.occupied">
        <span class="badge text-bg-dark rounded-pill">
          Occupied
        </span>
      </ng-container>
      <ng-container *ngIf="!apartment.occupied">
        <span class="badge text-bg-success rounded-pill" *ngIf="isAvailable()">Available</span>
        <span class="badge text-bg-warning rounded-pill" *ngIf="isBooked()">Booked</span>
        <span class="badge text-bg-primary rounded-pill" *ngIf="isParticallyBooked()">
          {{ getBookedRooms().length + "/" + apartment.rooms.length + " Booked"}}
        </span>
      </ng-container>
    </div>
  </div>
</div>