import {Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs';
import { LayoutService } from 'src/app/services/layout.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  active: string = "listings";
  constructor(
    private router: Router,
    public layoutService: LayoutService
  ) {
    
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd && ['/user', '/listing', '/booking-request', '/reservation', '/invoice'].indexOf(e.url) >= 0)).subscribe(e => {
      this.active = (e as any).url.substring(1) + 's';
    })
  }

  getUrlPath(): string {
    return this.router.url;
  }

  isManagementPage() {
    return (
      this.router.url.startsWith('/listing') ||
      this.router.url.startsWith('/reservation') ||
      this.router.url.startsWith('/booking-request') ||
      this.router.url.startsWith('/user') ||
      this.router.url.startsWith('/invoice') ||
      this.router.url.startsWith('/payout')
    );
  }

  isDetailPage() {
    if (this.getUrlPath().match('\/listing\/([a-z]|[0-9])+')
      || this.getUrlPath().match('\/user\/([a-z]|[0-9])+')
      || this.getUrlPath().match('\/reservation\/([a-z]|[0-9])+')
      || this.getUrlPath().match('\/booking-request\/([a-z]|[0-9])+')
      || this.getUrlPath().match('\/invoice\/([a-z]|[0-9])+')
    ) {
      return true;
    }
    return false;
  }

  isLoginPage() {
    return (this.getUrlPath().match('\/login'));
  }

}