import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/utils/utils';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  constructor(
    private utils: Utils,
    public login: LoginService,
    private router: Router,
    public layoutService: LayoutService
  ) { }

  ngOnInit(): void {
  }

  getUrlPath() {
    return this.utils.getUrlPath();
  }

  logout(): void {
    this.login.logout().subscribe(_ => {
      this.login.setLoggedIn(false);
      this.router.navigate(['/login']);
    }, _ => {
      this.login.setLoggedIn(false);
      this.router.navigate(['/login']);
    })
  }

  navigate(route: string): void {
    this.router.navigate([route]);
  }

}
