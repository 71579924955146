import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { EmailService } from 'src/app/services/email.service';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string = '';
  pwd: string = '';
  verificationCode: string = '';
  loginSuccess: boolean = true;
  err: any;
  resendTimerSubscription: any = null as any;
  timeLeftToResend = 60;
  pendingResend: boolean = false;
  

  constructor(
    private router: Router,
    private loginService: LoginService,
    private authServce: AuthService,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
  }

  login(): void {
    this.loginService.login(this.email, this.pwd, this.verificationCode).subscribe(resp => {
      this.loginService.setLoggedIn(true);
      this.authServce.setToken((resp as any).token.value);
      this.authServce.setLoggedId((resp as any).user._id);
      this.router.navigate(['/user']);
    }, (err) => {
      this.loginService.setLoggedIn(false);
      this.loginSuccess = false;
      this.err = err;
    })
  }

  isAbleToSend(): boolean {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(this.email) && !this.pendingResend;
  }

  sendEmailVerificationCode(): void {
    this.emailService.sendVerificationCode(this.email).subscribe(resp => {
      this.loginSuccess = true;
      this.timeLeftToResend = 60;
      this.pendingResend = true;
      this.resendTimerSubscription = setInterval(() => {
      this.timeLeftToResend -= 1;
      if (this.timeLeftToResend === 0) {
        this.pendingResend = false;
        clearInterval(this.resendTimerSubscription);
      }
    }, 1000);
    }, err => {
      this.loginSuccess = false;
      this.err = err.error;
    })

    

  }

}
