<div class="row">
    <div class="col-auto">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/user">User</a></li>
                <li class="breadcrumb-item active text-secondary" aria-current="page">
                    {{ user._id }}
                </li>
            </ol>
        </nav>
    </div>
</div>

<div class="container card py-2">
    <div class="row mt-2" *ngIf="reservations.length > 0">
        <div class="col-12">
            <div class="alert alert-primary fs-14 mt-2 mx-4" role="alert" *ngFor="let reservation of reservations">
                <div class="row align-items-center">
                    <div class="col-auto">
                        Current reservation: 
                        <span class="fw-bold fs-14">
                            {{ dateUtil.createDate(reservation.moveInDate) | date: 'mediumDate' }}
                        </span>
                        -
                        <span class="fw-bold fs-14">
                            {{ dateUtil.createDate(reservation.moveOutDate) | date: 'mediumDate' }}
                        </span>
                    </div>
                    <div class="col text-end">
                        <a target="_blank" class="btn btn-outline-dark p-1 fs-14" [routerLink]="['/reservation', reservation._id]">View</a>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="row mt-2" *ngIf="pendingRequests.length > 0">
        <div class="col-12">
            <div class="alert alert-primary fs-14 mt-2 mx-4" role="alert" *ngFor="let pendingRequest of pendingRequests">
                <div class="row align-items-center">
                    <div class="col-auto">
                        Current Pending Request: 
                        <span class="fw-bold fs-14">
                            {{ dateUtil.createDate(pendingRequest.moveInDate) | date: 'mediumDate' }}
                        </span>
                        -
                        <span class="fw-bold fs-14">
                            {{ dateUtil.createDate(pendingRequest.moveOutDate) | date: 'mediumDate' }}
                        </span>
                    </div>
                    <div class="col text-end">
                        <a target="_blank" class="btn btn-outline-dark p-1 fs-14" [routerLink]="['/booking-request', pendingRequest._id]">View</a>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="row mt-2 px-4">
        <div class="col-auto" id="user-image">
            <div>
                <img src="{{user.image}}" alt="" class="rounded-circle">
            </div>
            
        </div>
        <div class="col">
            <ng-container>
                <div class="row mt-2">
                    <div class="col-auto">
                        <h3>
                            <span class="name">{{user.first_name + " " + user.last_name}}</span>
                            
                            <span class="age" *ngIf="user.birthDate">
                                , {{ calculateAge()}}
                            </span>
                        </h3>
                    </div>
                    <div class="col text-end">
                        <ng-container *ngIf="user.isBlocked">
                            <span class="badge text-bg-secondary rounded-pill">
                                Blocked
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!user.isBlocked">
                           <span class="badge text-bg-success rounded-pill" *ngIf="user.emailVerified">
                                Verified
                           </span> 
                           <span class="badge text-bg-danger rounded-pill" *ngIf="!user.emailVerified">
                                Unverified
                           </span>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto">
                        <span class="badge text-bg-secondary rounded-pill">{{user.status}}</span>
                        
                        <span class="d-inline-block text-secondary fs-14 ms-2" *ngIf="pendingRequests.length == 0">
                            No booking requests
                        </span>
                        <a 
                            href="javascript:;" 
                            class="d-inline-block ms-2 text-decoration-none text-decoration-underline fs-14" 
                            *ngIf="pendingRequests.length != 0"
                            (click)="openModal(pendingRequestContent, 'lg')"
                        >
                            <ng-container *ngIf="pendingRequests.length > 1">
                                {{ pendingRequests.length }} booking requests
                            </ng-container>
                            <ng-container *ngIf="pendingRequests.length == 1">
                                1 booking request
                            </ng-container>
                        </a>
                    </div>
                    <div class="col-auto"></div>
                    <div class="col text-end" id="join-date">
                       Member since {{ dateUtil.createDate(user.creationDate) | date: 'mediumDate'}} 
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="row mt-4 px-4">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Details</a>
                <ng-template ngbNavContent>
                    <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                        <div class="row mt-2">
                        
                            <div class="row">
                                <div class="col text-secondary fs-14">Email</div>
                                <div class="col text-secondary fs-14">Phone Number</div>
                                <div class="col text-secondary fs-14">Status</div>
                                <div class="col text-secondary fs-14">Language of Correspondance</div>
                            </div>
                            <div class="row">
                                <div class="col fs-14 fw-bold">
                                    <ng-container *ngIf="user.email">
                                        {{ user.email }}
                                    </ng-container>
                                    <ng-container *ngIf="!user.email">
                                        /
                                    </ng-container>
                                </div>
                                <div class="col fs-14 fw-bold">
                                    <ng-container *ngIf="user.phone">
                                        {{ user.phone }}
                                    </ng-container>
                                    <ng-container *ngIf="!user.phone">
                                        <ng-container *ngIf="user.unverified_phone">
                                            {{ user.unverified_phone }}
                                            <span class="badge text-bg-danger rounded-pill">
                                                Unverified
                                           </span>
                                        </ng-container>
                                        <ng-container *ngIf="!user.unverified_phone">
                                            /
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="col fs-14 fw-bold">
                                    <ng-container *ngIf="user.status">
                                        {{ user.status}}
                                    </ng-container>
                                    <ng-container *ngIf="!user.status">
                                        /
                                    </ng-container>
                                </div>
                                <div class="col fs-14 fw-bold">{{ getLanguage() }}</div>
                            </div>
                            
                        </div>
                        <div class="row mt-2 mb-2">
                            <div class="row">
                                <div class="col text-secondary fs-14">Birth Date</div>
                                <div class="col text-secondary fs-14">Gender</div>
                                <div class="col text-secondary fs-14">Language Spoken</div>
                                <div class="col text-secondary fs-14">Provider</div>
                            </div>
                            <div class="row">
                                <div class="col fs-14 fw-bold">
                                    <ng-container *ngIf="user.birthDate">
                                        {{ dateUtil.createDate(user.birthDate) | date: 'mediumDate'}}
                                    </ng-container>
                                    <ng-container  *ngIf="!user.birthDate">
                                        /
                                    </ng-container>
                                </div>
                                <div class="col fs-14 fw-bold">
                                    <ng-container *ngIf="user.gender">
                                        {{ user.gender }}
                                    </ng-container>
                                    <ng-container *ngIf="!user.gender">
                                        /
                                    </ng-container>
                                </div>
                                <div class="col fs-14 fw-bold">
                                    {{ getLanguageSpoken() }}
                                </div>
                                <div class="col fs-14 fw-bold">
                                    <ng-container *ngIf="user.provider">
                                        {{ user.provider }}
                                    </ng-container>
                                    <ng-container *ngIf="!user.provider">
                                        /
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                        <div id="email">
                            <span class="text-secondary">
                                Email:
                            </span>
                            <span>
                                {{ user.email }}
                            </span>
        
                        </div>
                        <div>
                            <span class="text-secondary">
                                Phone:
                            </span>
                            <span>
                                <ng-container *ngIf="user.phone">
                                    {{ user.phone }}
                                </ng-container>
                                <ng-container *ngIf="!user.phone">
                                    <ng-container *ngIf="user.unverified_phone">
                                        {{ user.unverified_phone }}
                                        <span class="badge text-bg-danger rounded-pill">
                                            Unverified
                                       </span>
                                    </ng-container>
                                    <ng-container *ngIf="!user.unverified_phone">
                                        /
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                        <div>
                            <span class="text-secondary">
                                Status:
                            </span>
                            <span>
                                <ng-container *ngIf="user.status">
                                    {{ user.status}}
                                </ng-container>
                                <ng-container *ngIf="!user.status">
                                    /
                                </ng-container>
                            </span>
                        </div>
                        <div>
                            <span class="text-secondary">
                                LoC:
                            </span>
                            <span>
                                {{ getLanguage() }}
                            </span>
                        </div>
                        <div class="row"></div>
                    </ng-container>

                    <div class="aboutme mb-2">
                        <div class="row mt-4">
                            <div class="col-auto text-secondary fs-14">
                                About {{ user.first_name }}
                            </div>
                            
                        </div>
                        <div class="row mt-1">
                            <div class="col-10">
                                {{ user.aboutme }}
                            </div>
                        </div>
                    </div>

                    <div class="matching-info mt-4">
                        <div class="row">
                            <div class="row">
                                <div class="col fw-bold fs-14">Personality</div>
                                <div class="col fw-bold fs-14">Lifestyle</div>
                                <div class="col fw-bold fs-14">Interests</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <div class="row row-cols-3">
                                        <span *ngIf="!user.matching_info || user.matching_info.personality.length == 0" class="fs-14">
                                            /
                                        </span>
                                        <div class="col" *ngFor="let personality of user.matching_info.personality">
                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                {{ personality.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row row-cols-3">
                                        <span class="fs-14" *ngIf="!user.matching_info || user.matching_info.lifestyle.length == 0">
                                            /
                                        </span>
                                        <div class="col" *ngFor="let lifestyle of user.matching_info.lifestyle">
                                            
                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                {{ lifestyle.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row row-cols-3">
                                        <span class="fs-14" *ngIf="!user.matching_info || user.matching_info.interests.length == 0">
                                            /
                                        </span>
                                        <div class="col" *ngFor="let interest of user.matching_info.interests">
                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                {{ interest.name }}
                                            </span>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="row">
                                <div class="col fw-bold fs-14">Music</div>
                                <div class="col fw-bold fs-14">Sports</div>
                                <div class="col fw-bold fs-14">Cinema</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <div class="row row-cols-3">
                                        <span class="fs-14" *ngIf="!user.matching_info || user.matching_info.music.length == 0">
                                            /
                                        </span>
                                        <div class="col" *ngFor="let music of user.matching_info.music">
                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                {{ music.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row row-cols-3">
                                        <span class="fs-14" *ngIf="!user.matching_info || user.matching_info.sports.length == 0">
                                            /
                                        </span>
                                        <div class="col" *ngFor="let sport of user.matching_info.sports">
                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                {{ sport.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row row-cols-3">
                                        <span class="fs-14" *ngIf="!user.matching_info || user.matching_info.movies.length == 0">
                                            /
                                        </span>
                                        <div class="col" *ngFor="let movie of user.matching_info.movies">
                                            <span class="badge rounded-pill px-3 py-2 text-bg-light border border-dark-subtle fs-14">
                                                {{ movie.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    


                </ng-template>
            </li>

            <li [ngbNavItem]="2">
                <a ngbNavLink>Active Listings</a>
                <ng-template ngbNavContent>
                    <div class="row border-bottom border-top py-2 mt-2" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                        <div class="col fw-bold">#</div>
                        <div class="col fw-bold">Listing Title</div>
                        <div class="col fw-bold">Type</div>
                        <div class="col fw-bold">Bdrs #</div>
                        <div class="col fw-bold">Status</div>
                    </div>
                    <div class="row border-bottom py-2 clickable" (click)="open(apartment._id)" *ngFor="let apartment of apartments">
                        <div class="col fw-bold text-truncate">
                            {{ apartment._id }}
                        </div>
                        <div class="col fw-bold text-truncate">
                            {{ apartment.name }}
                        </div>
                        <div class="col">
                            <span class="badge text-bg-secondary rounded-pill" *ngIf="apartment.wholeApartment">
                                <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                    EP
                                </ng-container>
                                <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                    Entire Place
                                </ng-container>
                            </span>
                            <span class="badge text-bg-secondary rounded-pill" *ngIf="!apartment.wholeApartment">
                                <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                    PB
                                </ng-container>
                                <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                    <ng-container *ngIf="apartment.rooms.length > 1">
                                        Private Bedrooms
                                    </ng-container>
                                    <ng-container *ngIf="apartment.rooms.length == 1">
                                        Private Bedroom
                                    </ng-container>
                                </ng-container>
                                
                            </span>
                        </div>
                        <div class="col">
                            {{ apartment.rooms.length }}
                        </div>
                        <div class="col">
                            <ng-container *ngIf="apartment.wholeApartment">
                                <span class="badge text-bg-warning rounded-pill" *ngIf="bookedRooms[apartment._id]">
                                    <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                        B
                                    </ng-container>
                                    <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                        Booked
                                    </ng-container>
                                </span>
                                <span class="badge text-bg-success rounded-pill" *ngIf="!bookedRooms[apartment._id]">
                                    <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                        A
                                    </ng-container>
                                    <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                        Available
                                    </ng-container>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!apartment.wholeApartment">
                                <ng-container *ngIf="bookedRooms[apartment._id]">
                                    <span class="badge text-bg-primary rounded-pill">{{ getBookedRoomsCount(apartment._id)}}/{{ apartment.rooms.length}} 
                                        <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                            B
                                        </ng-container>
                                        <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                            Booked
                                        </ng-container>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!bookedRooms[apartment._id]">
                                    <span class="badge text-bg-success rounded-pill">
                                        <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                            A
                                        </ng-container>
                                        <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                            Available
                                        </ng-container>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    
</div>

<ng-template #pendingRequestContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Pending Requests</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="d-flex flex-column ">
            <div class="alert alert-primary fs-14 mt-2 mx-4" role="alert" *ngFor="let pr of pendingRequests">
                <div class="row align-items-center">
                    <div class="col-auto">
                        Pending Request:
                        <span class="fw-bold">
                            {{ dateUtil.createDate(pr.moveInDate) | date: 'mediumDate'}} - {{ dateUtil.createDate(pr.moveOutDate) | date: 'mediumDate' }}
                        </span> 
                    </div>
                    <div class="col text-end">
                        <a class="btn btn-outline-dark" [routerLink]="['/booking-request', pr._id]">View</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
</ng-template>