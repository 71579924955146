export const environment = {
  production: true,
  socketUrl: 'https://api.hivenue.ca',
  apiUrl: "https://api.hivenue.ca/",
  frontEndUrl: "https://hivenue.ca/",
  imageHost: "https://s3.ca-central-1.amazonaws.com/media.wemoove.ca/",
  invoiceUrl: "https://dashboard.stripe.com/invoices/",
  stripeAccountId: "acct_1Jw8OQAWdAnA3s5C",
  stripeUrl: "https://dashboard.stripe.com"
};
