<div class="row">
  <div class="col-auto">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/reservation">Reservations</a>
        </li>
        <li class="breadcrumb-item active text-secondary" aria-current="page">
          {{ reservation._id }}
        </li>
      </ol>
    </nav>
  </div>
</div>

<div class="basic-info container">
  <div class="row">
    <div class="col-5" [ngClass]="layoutService.isLessThanSizeForFullDisplay() ? 'col-12' : ''">
      <a href="javascript:0;">
        <img
          src="{{ getImgHost() }}apartment/{{
            reservation.apartment.photos[0].source
          }}"
          class="w-100"
          *ngIf="reservation.apartment.photos.length > 0"
        />
      </a>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-8 text-left">
          <div class="row">
            <h3>{{ reservation.apartment.name }}</h3>
          </div>
          <div class="row mt-2">
            <p>
              <i class="bi bi-geo-alt"></i>
              <a
                href="javascript:0;"
                class="text-decoration-none text-decoration-underline"
              >
                {{ reservation.apartment.adress.adress }}
              </a>
            </p>
          </div>
          <div class="row mt-2">
            <div class="col-auto">
              <span
                class="badge text-bg-secondary rounded-pill"
                *ngIf="!reservation.apartment.wholeApartment"
              >
                <ng-container *ngIf="reservation.roomIds.length > 1">
                  {{ reservation.roomIds.length }} Private Bedrooms
                </ng-container>
                <ng-container *ngIf="reservation.roomIds.length == 1">
                  {{ reservation.roomIds.length }} Private Bedroom
                </ng-container>
              </span>
              <span
                class="badge text-bg-secondary rounded-pill"
                *ngIf="reservation.apartment.wholeApartment"
              >
                Entire Place
              </span>
            </div>
            <ng-container *ngIf="!flagEditDate">
              <div class="col">
                <span
                  class="badge fw-normal bg-light text-dark rounded-pill fs-14"
                >
                  Move-in/Move-out:
                  <span class="fw-bold">
                    {{
                      createDate(reservation.moveInDate) | date : "mediumDate"
                    }}
                    -
                    {{
                      createDate(reservation.moveOutDate) | date : "mediumDate"
                    }}
                  </span>
                </span>
                <i
                  class="bi bi-pencil-square clickable"
                  (click)="flagEditDate = true"
                ></i>
              </div>
            </ng-container>
            <ng-container *ngIf="flagEditDate">
              <div class="row mt-2">
                <div class="col">Move-in</div>
                <div class="col">Move-out</div>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    type="date"
                    [ngModel]="
                      createDate(reservation.moveInDate) | date : 'yyyy-MM-dd'
                    "
                    (ngModelChange)="changeMoveInDate($event)"
                    [disabled]="isActive() || isCompleted()"
                  />
                </div>
                <div class="col">
                  <input
                    type="date"
                    [ngModel]="
                      createDate(reservation.moveOutDate) | date : 'yyyy-MM-dd'
                    "
                    (ngModelChange)="changeMoveOutDate($event)"
                    [disabled]="isCompleted()"
                  />
                </div>
              </div>
              <div class="mt-4">
                <button
                  class="mr-2"
                  style="margin-right: 10px"
                  [disabled]="!isDateValidToChange"
                  (click)="updateReservationDate()"
                >
                  confirm
                </button>
                <button (click)="flagEditDate = false">cancel</button>
              </div>
            </ng-container>
          </div>
          <div class="row mt-4">
            <div class="col-auto">
              <a
                (click)="generateAgreementPDF()"
                class="btn btn-outline-dark"
                *ngIf="!reservation.agreementDocument"
              >
                Generate Agreement PDF
              </a>
            </div>
            <div class="col-auto">
              <a
                (click)="sendAgreementToHost()"
                class="btn btn-outline-dark"
                *ngIf="reservation.agreementDocument"
              >
                Send Agreement to Guest
              </a>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-auto">
              <a
                [routerLink]="['/listing', reservation.apartment._id]"
                class="btn btn-outline-dark"
                target="_blank"
              >
                See Listing Details
              </a>
            </div>
            <div class="col-auto">
              <a
                class="btn btn-danger"
                (click)="cancelReservation()"
                *ngIf="reservation.status !== 'ADMIN_CANCELLED'"
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row text-end">
            <span class="fs-14 text-secondary"> Total Paid </span>
          </div>
          <div class="row mt-2">
            <h3 class="text-end">
              {{
                calculateTotalPaid()
                  | currency : "CAD" : "symbol-narrow" : "1.0-2"
              }}
            </h3>
          </div>
          <div class="row mt-2">
            <div class="col text-end fs-14">
              Booked
              {{ createDate(reservation.createdAt) | date : "mediumDate" }}
            </div>
          </div>
          <div class="row justify-content-end mt-2">
            <div class="col-auto">
              <span
                class="badge text-bg-success rounded-pill"
                *ngIf="isUpComing() && !isCanceledByAdmin()"
              >
                Upcoming
              </span>
              <span
                class="badge text-bg-warning rounded-pill"
                *ngIf="isActive() && !isCanceledByAdmin()"
              >
                Active
              </span>
              <span
                class="badge text-bg-primary rounded-pill"
                *ngIf="isCompleted() && !isCanceledByAdmin()"
              >
                Completed
              </span>
              <span
                class="badge text-bg-danger rounded-pill"
                *ngIf="isCanceledByAdmin()"
              >
                Canceled by Admin
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" >
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeId">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Details</a>

        <ng-template ngbNavContent>
          <div class="container">
            <div class="row mt-2"[ngClass]="layoutService.isLessThanSizeForFullDisplay() ? 'flex-column' : ''">
              <div class="col-auto">
                <img
                  src="{{ reservation.userId.image }}"
                  alt=""
                  class="rounded-circle"
                />
              </div>
              <div class="col">
                <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                  <div class="row mt-2">
                    <div class="col">
                      <span class="text-secondary fs-14">Guest Name</span>
                    </div>
                    <div class="col">
                      <span class="text-secondary fs-14">Guest ID</span>
                    </div>
                    <div class="col">
                      <span class="text-secondary fs-14">Purpose of Stay</span>
                    </div>
                    <div class="col">
                      <span class="text-secondary fs-14">Monthly Rent</span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <a
                        href="javascript:0"
                        class="text-decoration-none text-decoration-underline fw-bold fs-14"
                      >
                        {{ reservation.userId.first_name }}
                        {{ reservation.userId.last_name }}
                      </a>
                    </div>
                    <div class="col">
                      <span class="fs-14 fw-bold">
                        {{ reservation.userId._id }}
                      </span>
                    </div>
                    <div class="col">
                      <span class="fs-14 fw-bold">
                        {{ getPurposeOfStay() }}
                      </span>
                    </div>
                    <div class="col" *ngIf="!flagEditPrice">
                      <span class="fs-14 fw-bold">
                        {{
                          reservation.originalRent
                            | currency : "CAD" : "symbol-narrow" : "1.0-2"
                        }}
                        <i
                          class="bi bi-pencil-square clickable"
                          (click)="flagEditPrice = true"
                        ></i>
                      </span>
                    </div>
                    <div class="col" *ngIf="flagEditPrice">
                      <input type="text" [(ngModel)]="newPrice" />
                      <button (click)="updateReservationPrice()">confirm</button>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                  <div class="row">
                    <div class="col-3 text-secondary">
                      Name: 
                    </div>
                    <div class="col">
                      <a
                      href="javascript:0"
                      class="text-decoration-none text-decoration-underline fw-bold fs-14"
                    >
                      {{ reservation.userId.first_name }}
                      {{ reservation.userId.last_name }}
                    </a> 
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-secondary">ID</div>
                    <div class="col fw-bold">
                        {{ reservation.userId._id }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-secondary">PoS</div>
                    <div class="col fw-bold">
                      {{ getPurposeOfStay() }}
                    </div>
                  </div>
                  <div class="row" *ngIf="flagEditPrice">
                    <div class="col-3 text-secondary">Rent: </div>
                    <div class="col">
                      <input type="text" [(ngModel)]="newPrice" />
                      <button (click)="updateReservationPrice()">confirm</button>
                    </div>
                  </div>
                  <div class="row" *ngIf="!flagEditPrice">
                    <div class="col-3 text-secondary">Rent: </div>
                    <div class="col fw-bold">
                      {{
                        reservation.originalRent
                          | currency : "CAD" : "symbol-narrow" : "1.0-2"
                      }}
                      <i
                        class="bi bi-pencil-square clickable"
                        (click)="flagEditPrice = true"
                      ></i>
                    </div>
                  </div>
                  
                </ng-container>
                
                <div class="row mt-4">
                  <div class="row">
                    <div class="col-auto">
                      <span class="text-secondary fs-14">{{ isLongTerm ? 'Subscription' : 'Payment' }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <span class="text-secondary fs-14"><a href="{{ getSubscriptionOrPaymentUrl() }}" target="_blank">{{ getSubscriptionOrPaymentUrl() }}</a></span>
                    </div>
                  </div>
                </div>
                
                <div class="row mt-4">
                  <div class="row">
                    <div class="col-auto">
                      <span class="text-secondary fs-14"> Documents </span>
                    </div>
                  </div>
                  <ngb-accordion class="justify-content-center col-6" [ngClass]="layoutService.isLessThanSizeForFullDisplay() ? 'w-100' : ''">
                    <ngb-panel
                      *ngIf="
                        reservation.poi || reservation.userId.poi.length > 0
                      "
                    >
                      <ng-template ngbPanelTitle> PoI </ng-template>
                      <ng-template ngbPanelContent>
                        <div
                          class="row"
                          *ngFor="
                            let poi of [reservation.poi].concat(
                              reservation.userId.poi
                            )
                          "
                        >
                          <a
                            [href]="constructUrl('poi', poi)"
                            target="_blank"
                            *ngIf="poi"
                          >
                            {{
                              poi.filename
                                ? (poi.date | date : "mediumDate")
                                : "Legacy"
                            }}
                          </a>
                        </div>
                      </ng-template>
                    </ngb-panel>

                    <ngb-panel
                      *ngIf="
                        reservation.identity ||
                        reservation.userId.identity.length > 0
                      "
                    >
                      <ng-template ngbPanelTitle> Identity </ng-template>
                      <ng-template ngbPanelContent>
                        <div
                          class="row"
                          *ngFor="
                            let identity of [reservation.identity].concat(
                              reservation.userId.identity
                            )
                          "
                        >
                          <a
                            [href]="constructUrl('identity', identity)"
                            target="_blank"
                            *ngIf="identity"
                          >
                            {{
                              identity.filename
                                ? (identity.date | date : "mediumDate")
                                : "Legacy"
                            }}
                          </a>
                        </div>
                      </ng-template>
                    </ngb-panel>

                    <ngb-panel *ngIf="reservation.userId.pos.length > 0">
                      <ng-template ngbPanelTitle> PoS </ng-template>
                      <ng-template ngbPanelContent>
                        <div
                          class="row"
                          *ngFor="let pos of reservation.userId.pos"
                        >
                          <a
                            [href]="constructUrl('pos', pos)"
                            target="_blank"
                            *ngIf="pos"
                          >
                            {{
                              pos.filename
                                ? (pos.date | date : "mediumDate")
                                : "Legacy"
                            }}
                          </a>
                        </div>
                      </ng-template>
                    </ngb-panel>

                    <ngb-panel
                      *ngIf="
                        reservation.otherDocument ||
                        reservation.userId.otherDocument.length > 0
                      "
                    >
                      <ng-template ngbPanelTitle> Other Document </ng-template>
                      <ng-template ngbPanelContent>
                        <div
                          class="row"
                          *ngFor="
                            let otherDocument of [
                              reservation.otherDocument
                            ].concat(reservation.userId.otherDocument)
                          "
                        >
                          <a
                            [href]="
                              constructUrl('otherDocument', otherDocument)
                            "
                            target="_blank"
                            *ngIf="otherDocument"
                          >
                            {{
                              otherDocument.filename
                                ? (otherDocument.date | date : "mediumDate")
                                : "Legacy"
                            }}
                          </a>
                        </div>
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel *ngIf="reservation.agreementDocument">
                      <ng-template ngbPanelTitle> Agreement </ng-template>
                      <ng-template ngbPanelContent>
                        <a
                          [href]="
                            constructUrl(
                              'agreement',
                              reservation.agreementDocument
                            )
                          "
                          target="_blank"
                          *ngIf="reservation.agreementDocument"
                        >
                          {{ "Agreement" }}
                        </a>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>
                <div class="row mt-5">
                  <div class="row">
                    <div class="col-auto">
                      <span class="text-secondary fs-14"> Message </span>
                    </div>
                  </div>
                  <div class="row col-8 mt-2" [ngClass]="layoutService.isLessThanSizeForFullDisplay() ? 'w-100' : ''">
                    <p>
                      {{ reservation.message }}
                    </p>
                  </div>
                  <ng-template #messageContent let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title">Message</h4>
                      <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <p class="text-secondary">
                        {{ reservation.message }}
                      </p>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-light"
                        (click)="modal.close('Close click')"
                      >
                        Close
                      </button>
                    </div>
                  </ng-template>
                  <div class="row">
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-link mt-2 justify-self-start align-self-bottom p-0 text-secondary fw-bold text-decoration-none fs-13"
                        (click)="openModal(messageContent, 'xl')"
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Payment Details</a>

        <ng-template ngbNavContent>
          <mat-tab-group animationDuration="0ms">
            <mat-tab label="Payment History">
              <div class="container">
                <div class="row border-top border-bottom py-2">
                  <div class="col-4 fw-bold">Period</div>
                  <div class="col-4 fw-bold">Amount</div>
                  <div class="col-2 fw-bold">Status</div>
                  <div class="col-2 fw-bold">Paid On</div>
                </div>
                <ng-container *ngFor="let payout of existingPayouts; let i = index">
                  <div class="row border-top border-bottom py-2">
                    <div class="col-4">{{ payout.periodObj.format() }}</div>
                    <div class="col-4">{{ payout.amountDue | currency: 'CAD': 'symbol-narrow': '1.0-2'}}</div>
                    <div class="col-2">{{ payout.status }}</div>
                    <div class="col-2">{{ payout.paidDate }}</div>
                  </div>
                </ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Contact</a>
        <ng-template ngbNavContent>
          <div class="container">
            <div class="row">
              <div class="col-auto" [ngClass]="layoutService.isLessThanSizeForFullDisplay() ? 'col-3' : ''">
                <img
                  src="{{ reservation.userId.image }}"
                  alt=""
                  class="rounded-circle"
                  style="max-width: 100%"
                />
              </div>
              <div class="col align-items-center py-4" [ngClass]="layoutService.isLessThanSizeForFullDisplay() ? 'col-9' : ''">
                <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                  <div class="row align-self-center">
                    <div class="col-3 text-secondary fs-14">Full Name</div>
                    <div class="col-3 text-secondary fs-14">Phone Number</div>
                    <div class="col-6 text-secondary text-start fs-14">Email</div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <a
                        href="javascript:;"
                        class="text-decoration-none text-decoration-underline fw-bold fs-14"
                      >
                        {{
                          reservation.userId.first_name +
                            " " +
                            reservation.userId.last_name
                        }}
                      </a>
                    </div>
                    <div class="col-3 fw-bold fs-14">
                      {{ reservation.userId.phone }}
                    </div>
                    <div class="col-6 fw-bold fs-14">
                      {{ reservation.userId.email }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                  <div class="row">
                    <div class="col-3 text-secondary">Name: </div>
                    <div class="col fw-bold">
                      <a
                        href="javascript:;"
                        class="text-decoration-none text-decoration-underline fw-bold fs-14"
                      >
                        {{
                          reservation.userId.first_name +
                            " " +
                            reservation.userId.last_name
                        }}
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-secondary">Phone: </div>
                    <div class="col fw-bold">
                      {{ reservation.userId.phone }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-secondary">Email: </div>
                    <div class="col fw-bold">
                      {{ reservation.userId.email }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>