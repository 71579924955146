import { Component, Input, OnInit } from '@angular/core';
import { Constant, RENT_REQUEST_STAGES, RENT_REQUEST_STATUS } from 'src/app/utils/constant';
import { DateUtil } from 'src/app/utils/dateUtil';

@Component({
  selector: 'app-booking-request',
  templateUrl: './booking-request.component.html',
  styleUrls: ['./booking-request.component.css']
})
export class BookingRequestComponent implements OnInit {
  @Input() bookingRequest: any = null as any;

  constructor(
    public dateUtil: DateUtil
  ) { }

  isWholeApartment() {
    return this.bookingRequest.apartmentId.wholeApartment;
  }

  isPendingAdmin() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.ADMIN &&
       this.bookingRequest.status == RENT_REQUEST_STATUS.PENDING)
  }

  isPendingHost() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.HOST && 
      this.bookingRequest.status == RENT_REQUEST_STATUS.PENDING)
  }

  isDeclinedByAdmin() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.ADMIN
      && this.bookingRequest.status == RENT_REQUEST_STATUS.DECLINED);
  }

  isDeclinedByHost() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.HOST 
        && this.bookingRequest.status == RENT_REQUEST_STATUS.DECLINED);
  }

  isPaymentFailed(): boolean {
    return (this.bookingRequest.status == RENT_REQUEST_STATUS.PAYMENT_FAILED)
  }

  isClosedByHost(): boolean {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.HOST
        && this.bookingRequest.status == RENT_REQUEST_STATUS.CLOSED
      );
  }

  isClosedBySystem(): boolean {
    return (this.bookingRequest.status == RENT_REQUEST_STATUS.CLOSED_BY_SYSTEM);
  }

  calculateTotal2Pay() {
    if (this.isWholeApartment()) {

    } else {

    }
  }

  ngOnInit(): void {
  }

}
