
<div class="container d-flex flex-column justify-content-center align-items-center" style="height: 700px">
    <h2>Login</h2>
    <form action="">
        <div class="username mb-3">
            <label for="username">Email</label>
            <input type="text" class="form-control" name="email" id="email" [(ngModel)]="email">
        </div>
        <div class="pwd mb-3">
            <label for="pwd">Password</label>
            <input type="password" class="form-control" name="password" id="password" [(ngModel)]="pwd">
        </div>
        <div class="verification mb-3">
            <label for="pwd">Verification Code
                <input type="password" class="form-control" name="password" id="password" [(ngModel)]="verificationCode">
            </label>
            <button class="ms-3 btn btn-success" [disabled]="!isAbleToSend()" *ngIf="pendingResend" (click)="sendEmailVerificationCode()">
                Resend in {{ timeLeftToResend }}s
            </button> 
            <button class="ms-3 btn btn-success" [disabled]="!isAbleToSend()" *ngIf="!pendingResend" (click)="sendEmailVerificationCode()">
                Send 
            </button>  
        </div>
        <div class="d-flex justify-content-center mt-2">
            <button class="d-block btn btn-success" (click)="login()">Login</button>
        </div>
    </form>
    <div class="alert alert-danger mt-2" role="alert" *ngIf="!loginSuccess">
        {{ err.message ? err.message : err.toString() }}
    </div>
</div>