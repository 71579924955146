import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apartment } from '../models/apartment/apartment';
import { Utils } from '../utils/utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ListingService {

  constructor(
    private util: Utils,
    private http: HttpClient,
    private authService: AuthService
  ) { }
  
  private _addQueryField(url: string, fieldName: string, field: string) {
    if (field.length > 0)
      return `${url}&${fieldName}=${field}`
    return `${url}&${fieldName}`;
  }

  searchApartments(params: {
    field: string,
    value: any
  }[]) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/apartments?`;
    for (let param of params) {
      url = this._addQueryField(url, param.field, param.value.toString())
    }
    return this.http.get<Array<Apartment>>(url, {headers});
  }

  getApprovedRooms(id: String) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/apartments/${id}/approved` 
    return this.http.get<Array<any>>(url, {headers});
  }

  getApartmentById(id: string | null) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/apartments/${id}`;
    return this.http.get<Apartment>(url, {headers});
  }

  isInReservation(moveInDate: Date, moveOutDate: Date): boolean {
    return false;
  }

  getApartmentsWithOwnerId(id: string) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/user/${id}/apartments`;
    return this.http.get<[]>(url, {headers});
  }

  updateApartment(id: string, body: any) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      ); 
    body.updateInfo = {
      performer: 'admin'
    }
    let url = `${this.util.getAPIUrl()}admin/apartments/${id}`;
    return this.http.put(url, body, { headers })
  }
}