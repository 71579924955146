import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loggedIn: boolean = false;

  constructor(
    private cookie: CookieService,
    private http: HttpClient,
    private utils: Utils,
    private router: Router,
    private authService: AuthService
  ) { }

  authroizeToken() {
    let tokenInfoCookie = this.cookie.get("token_info");
    if (!tokenInfoCookie) {
      tokenInfoCookie = "{}";
    }
    const token = JSON.parse(tokenInfoCookie);
    
    const headers = new HttpHeaders().set(
      "authorization", token['value']
    );
    return this.http.get(`${this.utils.getAPIUrl()}admin/verify`, { 'headers': headers });
  }

  login(email: string, password: string, verificationCode: string) {
    return this.http.post(
      `${this.utils.getAPIUrl()}admin/login`,
      {
        email, password, verificationCode
      }
    );
  }

  logout() {
    // ask server to clear infomation about the current user
    // do authorization again, and it will fail and navigate user to login
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return this.http.get(`${this.utils.getAPIUrl()}admin/logout`, {headers})
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  setLoggedIn(val: boolean) {
    this.loggedIn = val;
  }
}
